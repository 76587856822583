<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.81822 2.40898C7.24281 1.2732 8.37859 0.562012 9.51791 0.562012H13.9195C14.3441 0.562012 14.7722 0.562012 15.1968 0.703542C16.0495 0.845072 16.9023 1.55626 17.1853 2.40898C17.3268 2.83357 17.4684 3.2617 17.6099 3.82782H21.1588C21.87 3.82782 22.5776 4.11088 23.0057 4.53901C23.7169 5.10867 24 5.95785 24 6.66904V18.455C24 19.3077 23.7169 20.1604 23.0057 20.585C22.4361 21.1547 21.7284 21.4377 21.0172 21.4377H3.12428C1.9885 21.4377 0.99425 20.8681 0.424591 20.0189C0.14153 19.5943 0 19.0246 0 18.4585V6.66904C0 5.95785 0.283061 5.10867 0.85272 4.53901C1.42238 4.11442 2.13003 3.82782 2.84122 3.82782H6.39009C6.53162 3.25816 6.67315 2.83357 6.81468 2.40898H6.81822ZM9.23131 2.26745C8.80672 2.26745 8.37859 2.69204 8.23706 3.12017C8.09553 3.68983 7.954 4.25595 7.6674 4.82561C7.6674 4.96714 7.52587 5.2502 7.24281 5.2502C7.10128 5.39173 6.81822 5.39173 6.67315 5.39173H2.84122C2.13003 5.39173 1.56391 5.96139 1.56391 6.66904V18.455C1.56391 19.1662 2.2751 19.7323 2.84122 19.7323H21.4454C21.87 19.5908 22.2981 19.3077 22.4396 18.8796V6.66904C22.4396 6.24445 22.1566 5.67479 21.5869 5.53326C21.4454 5.39173 21.1623 5.39173 20.8757 5.39173H17.1818C16.7572 5.39173 16.4706 5.10867 16.3291 4.82207C16.1875 4.25241 15.9045 3.68629 15.7594 2.9751C15.4763 2.55051 15.0482 2.12238 14.4821 2.12238H9.65237C9.51084 2.12238 9.3693 2.12238 9.22777 2.26391L9.23131 2.26745Z"
            fill="currentColor"
        />
        <path
            d="M11.3614 6.24113C12.6387 6.0996 13.916 6.38266 14.9103 6.95232C16.3291 7.66351 17.3234 8.94082 17.7515 10.5012C18.1761 11.7785 18.0346 13.3424 17.4685 14.4782C16.8988 15.897 15.9081 17.0328 14.4857 17.6025C13.3499 18.1721 11.786 18.3137 10.5087 18.0271C9.08986 17.6025 7.95408 16.7498 7.10136 15.614C6.24864 14.4782 5.96558 13.0593 5.96558 11.637C6.10711 10.2181 6.8183 8.79575 7.95408 7.8015C8.8068 6.94878 10.0841 6.38266 11.3614 6.24113ZM11.5029 7.8015C10.7918 7.94303 10.0841 8.08456 9.65598 8.51269C8.80326 9.08235 8.09561 9.93153 7.80901 10.7842C7.52595 11.7785 7.52595 12.7728 7.95054 13.767C8.2336 14.6197 8.94479 15.4724 9.65598 15.897C10.5087 16.4667 11.6445 16.6082 12.4972 16.6082C13.3499 16.4667 14.0576 16.1836 14.6272 15.7555C15.4799 15.1858 16.0461 14.3367 16.3327 13.3424C16.4742 12.3482 16.4742 11.3539 16.0496 10.5012C15.7665 9.50694 15.0554 8.79575 14.2026 8.37116C13.3499 7.8015 12.3557 7.65997 11.5029 7.8015Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
