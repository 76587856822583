<script lang="tsx">
import { defineComponentBaseUiButton } from '@core/app/components/base/ui/BaseUiButton.vue'
import type { BaseUiInputColors, BaseUiInputVariants, BaseUiInputSizes } from '../../base/BaseUiInput.vue'

export default defineComponentBaseUiButton<BaseUiInputColors, BaseUiInputVariants, BaseUiInputSizes>({
    props: {
        size: {
            default: 'md',
        },
        color: {
            default: 'black',
        },
        variant: {
            default: 'solid',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiButton" as *;

@include button {
    border-radius: $mon-border-radius;

    transition: background-color $mon-trans-time-normal $mon-timing, color $mon-trans-time-normal $mon-timing;

    @include mon-text-base;
}

@include button--disabled {
    background-color: rgba($mon-c-primary-500, 0.3);
}

// --------------------------------------------------------------------
// Button sizes

@include button--size('md') {
    // TODO: use scss vars
    padding: 0.625rem 1rem; // vertical padding should match BaseUiInput

    @include mon-text-base;

    @include button--square('&') {
        padding: 0.625rem;
    }
}

// --------------------------------------------------------------------
// Button colors

@include button--color('white') {
    @include button--variant(solid, '&') {
        background-color: $mon-c-white; // background color should match BaseUiInput
        color: $mon-c-primary-500;

        @include button--hover('&') {
            background-color: $mon-c-basic-100; // background color should match BaseUiInput
        }
    }
}

@include button--color('black') {
    @include button--variant(solid, '&') {
        // TODO: use scss vars
        background-color: $mon-c-primary-100;   // background color should match BaseUiInput
        color: $mon-c-black;

        @include button--hover('&') {
            // TODO: use scss vars
            background-color: $mon-c-primary-200;   // background color should match BaseUiInput
        }
    }
}

</style>
