<template>
    <div class="mon-main-search">
        <!--  TODO: extract to a separate component called MainSearchInput  -->
        <BaseUiElementGroup>
            <BaseUiInput
                ref="inputComponentRef"
                v-model="query"
                :color="color"
                :placeholder="$t('labels.search')"
                autocomplete="off"
                clearable
                @keyup.enter="isResultsPopupOpen = true"
            >
                <template #leading>
                    <IconSearch class="ml-1" width="20" height="20" />
                </template>
            </BaseUiInput>

            <UiElementGroupDivider class="lg:hidden" />

            <UiButtonInput content-class="mon-text-extra-small text-nowrap" class="lg:hidden">
                {{ $t('labels.scan_ean') }}
                <template #trailing>
                    <IconCamera class="mr-2" width="24" height="21" />
                </template>
            </UiButtonInput>
        </BaseUiElementGroup>

        <!-- RESULTS POPUP -->
        <!--        <LazyMainSearchResultsPopup-->
        <!--            v-if="wasResultsPopupMounted && $refs.inputComponentRef"-->
        <!--            v-model="isResultsPopupOpen"-->
        <!--            :query="query"-->
        <!--            :ignore-elements="[$refs.inputComponentRef as HTMLElement]"-->
        <!--        />-->
    </div>
</template>

<script lang="ts" setup>
import type { BaseUiInput } from '#components'
import type { ComponentInstance } from '@core/types/utility'
import type { BaseUiInputColors } from './base/BaseUiInput.vue'

const {
    color = 'black',
} = defineProps<{
    color?: BaseUiInputColors
}>()

const query = ref<string>('')

const { is: isResultsPopupOpen, was: wasResultsPopupMounted } = useLazyMount()

watch(query, (val) => {
    isResultsPopupOpen.value = !!val
})

const inputComponentRef = ref<ComponentInstance<typeof BaseUiInput> | null>(null)
// @ts-ignore
const { focused } = useFocusWithin(inputComponentRef)

watch(focused, (isFocused) => {
    if (!isFocused || !query.value) return

    // delay the opening in case the clear button was clicked
    // (not to open it immediately)
    setTimeout(() => {
        if (!focused.value) return
        isResultsPopupOpen.value = true
    }, 100)
})

const route = useRoute()
watch(() => route.path, () => {
    isResultsPopupOpen.value = false
})

function focusInput() {
    // @ts-ignore
    inputComponentRef.value?.focusInput()
}

defineExpose({
    focusInput,
})


</script>

<style lang="scss" scoped>

.mon-main-search {
    position: relative;
    margin: 0 auto;

    @include more-than(md) {
        width: min(100%, 21.5rem);
        max-width: 21.5rem;
    }

    @include more-than(lg) {
        width: min(100%, 38.75rem);
        max-width: 38.75rem;
    }
}

.mon-navbar--mobile-is-open .mon-main-search {
    @include more-than(md) {
        width: 100%;
        max-width: 100%;
    }
}

</style>
