<template>
    <div class="mon-divider-wrapper">
        <div class="mon-divider" />
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.mon-divider-wrapper {
    position: relative;
}

.mon-divider {
    position: absolute;
    width: 1px;
    height: 75%;

    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    background-color: $mon-c-basic-200;

    z-index: 1;
}


</style>
